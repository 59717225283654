import React from "react";
import { connect } from "react-redux";
import { changeLang } from "../../reducers/actions/index";
import { navigate } from "gatsby";
import "./welcomePage.scss";
import { STRS_ENV, FG_ID, EMBEDDED_URL, STRS_API_URL } from '../../constants/global';

class WelcomePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <div className="welcome_page">
        <div>
          <h1>Bienvenue sur QVAX</h1>
          <h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </h2>
          <button onClick={() => this.next("fr")}>Entrer</button>
        </div>
        <div>
          <h1>Welkom bij QVAX</h1>
          <h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </h2>
          <button onClick={() => this.next("nl")}>Binnenkomen</button>
        </div>
        <div>
          <h1>Welcome to QVAX</h1>
          <h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </h2>
          <button onClick={() => this.next("en")}>Enter</button>
        </div>
        <div>
          <h1>Willkommen bei QVAX</h1>
          <h2>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </h2>
          <button onClick={() => this.next("de")}>Eingeben</button>
        </div>
      </div>
    )
  }

  next(lang) {
    this.props.changeLang(lang)
    navigate("/region", { state: { lang: lang } })
  }
}

const mapStateToProps = state => ({
  lang: state.lang,
})

const mapDispatchToProps = () => {
  return {
    changeLang,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(WelcomePage)
