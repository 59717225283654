import React, { Component } from "react"
import ContextProvider from "../components/context/contextProvider"
import { createStore } from "redux"
import { Provider } from "react-redux"
import allReducer from "../reducers"
import "../styles/shared.scss"
import WelcomePage from "../components/welcome-page/welcomePage"

let store
if (typeof window !== "undefined") {
  store = createStore(
    allReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
} else {
  store = createStore(allReducer)
}

class Register extends Component {
  render() {
    return (
      <ContextProvider>
        <Provider store={store}>
          <WelcomePage />
        </Provider>
      </ContextProvider>
    )
  }
}
export default Register
